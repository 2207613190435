import React from 'react'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Restaurants from './restaurants'

const Footer = ({ logo }) => {

    const { 
        wp: {
            siteSettings: {
                ACFGeneral
            }
        }
    } = useStaticQuery(footerQuery)

    const { copyright, footer1, footer2, restaurants } = ACFGeneral

    return (
        <footer className="wrap-footer pt-5" id="contact">
            <section className="wrap-footer__hours" style={{backgroundImage: `url(${footer1.background.localFile.publicURL})`}}>
                <div className="container py-5 mt-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="text-center fw-bold pb-2 fc-white ">{footer1.title}</h2>
                            <p className="text-center fw-bold fc-black">{footer1.description}</p>
                            <StaticImage src="../../../content/assets/images/decorative.png" alt="Decorative" className="d-inline-block"/>
                        </div>
                        <div className="col-12 mt-3 mt-lg-5">
                            <Restaurants data={restaurants} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrap-footer__copyright pt-lg-5" style={{backgroundImage: `url(${footer2.background.localFile.publicURL})`}}>
                <div className="w-100 pt-5">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-12 pb-5">
                                <GatsbyImage image={logo.desktopLogo.childImageSharp.gatsbyImageData} className="mx-auto mb-4" alt="Logo El Corcel Restaurant" />
                                <p className="text-center fc-white mb-0">{footer2.description}</p>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-white" />
                    <div className="text-center fc-white pb-3">© { new Date().getFullYear() } {copyright}</div>
                </div>
            </section>
        </footer>
    )
} 

export default Footer

export const footerQuery = graphql`
query footer {
    wp {
      siteSettings {
        ACFGeneral {
          copyright
          footer1 {
            background {
              localFile {
                publicURL
              }
            }
            description
            title
          }
          footer2 {
            description
            title
            background {
                localFile {
                    publicURL
                }
              }
          }
          restaurants {
            address
            name
            phone
            socialMedia {
              name
              url
            }
            schedule
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100, blurredOptions: {}, width: 300)
                    }
                }
            }
          }
        }
      }
    }
  }
`