import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import Menu from "./menu"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Header = ({ logo, title }) => {
	
	const [header, setHeader] = useState('normal')
	const [logoRes, setLogoRes] = useState(logo.desktopLogo.childImageSharp.gatsbyImageData)

	if(typeof window !== 'undefined'){
		
		window.addEventListener('scroll', function() { 
			if(window.scrollY >= 100){
				setHeader('active')
			}else{
				setHeader('normal')
			}
		})
		window.addEventListener('resize', function() { 
			if(window.innerWidth <= 991 ){
			  setLogoRes(logo.mobileLogo.childImageSharp.gatsbyImageData)
			}else{
			  setLogoRes(logo.desktopLogo.childImageSharp.gatsbyImageData)
			}
		})
	}

	useEffect(()=>{
		window.dispatchEvent(new Event('resize'));
	}, [])

	return (
		<header className={`wrap-header ${header}`}>
			<nav className="navbar navbar-expand-lg navbar-light">
				<div className="container">
					<Link to="/" className="navbar-brand">
						<GatsbyImage image={logoRes} alt={parse(title)} />
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#primaryMenu" aria-controls="primaryMenu" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<Menu/>
				</div>
			</nav>
		</header>
		)
	} 
	
	export default Header