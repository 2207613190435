import React from 'react'
import { CreateLocalLink } from "../../utils"
import { Link } from "gatsby"

const MenuItem = ({ menuItem, wordPressUrl }) => {

    return (
        <li className="menu-item">
            <Link
                className={`d-flex align-items-center flex-lg-column ${typeof window !== 'undefined' && window?.location.hash === CreateLocalLink(menuItem, wordPressUrl) ? "active" : ''}`}
                to={CreateLocalLink(menuItem, wordPressUrl)}
            >
                {menuItem.label}
            </Link>
        </li>
    );
}

export default MenuItem