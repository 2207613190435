import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faCalendarAlt,  } from '@fortawesome/free-regular-svg-icons'
import { faPhone, faStoreAlt } from '@fortawesome/free-solid-svg-icons'

const Restaurants = ({data}) => {
    return (
        <>
            <div className="wrap-restaurants mt-5 ">
                <div className="row gx-5">
                    { data && 
                        data.map((restaurant, index)=> {
                            return (
                                <div key={restaurant.name+index} className="col-lg-4 text-center py-3 py-lg-0">
                                    <GatsbyImage image={restaurant.image?.localFile.childImageSharp.gatsbyImageData} className="d-inline-block py-lg-0" alt=''/>
                                    <h3>{restaurant.name}</h3>
                                    <ul className="mb-lg-0 ps-0 text-start">
                                        <li><FontAwesomeIcon icon={faCalendarAlt} /> <p>{restaurant.schedule}</p></li>
                                        <li><FontAwesomeIcon icon={faPhone} /> <p>{restaurant.phone}</p></li>
                                        <li><FontAwesomeIcon icon={faStoreAlt} /> <p>{restaurant.address}</p></li>
                                    </ul>
                                    <div className="wrap-footer__social">
                                        { restaurant.socialMedia && restaurant.socialMedia.map((social, key) => {
                                            return (
                                                <a href={social.url} target="_blank" key={`social-${key}`} title={social.title} rel='noreferrer noopener'><FontAwesomeIcon icon={faFacebookF} /></a>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }) 
                    }   
                </div>
            </div>
        </>
    )
}

export default Restaurants