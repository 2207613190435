import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ isHomePage, children }) => {

	const {
		wp: {
			generalSettings: { title },
			siteSettings: { 
				ACFGeneral: {
					logo
				}
			}
		}
	} = useStaticQuery(query)
	
	return (
		<div className="global-wrapper" data-is-root-path={isHomePage}>
			<Header logo={logo} title={title} />
				<main>{children}</main>
			<Footer logo={logo} />
		</div>
	)
}

export default Layout

const query = graphql`query LogoAndGeneralQuery {
	wp {
		generalSettings {
			title
			description
		}
		siteSettings {
			ACFGeneral {
				logo {
					desktopLogo: localFile {
						childImageSharp {
							gatsbyImageData(width: 150, placeholder: NONE, layout: FIXED)
						}
					}
					mobileLogo: localFile {
						childImageSharp {
							gatsbyImageData(width: 120, placeholder: NONE, layout: FIXED)
						}
					}
				}
			}
		}
	}
}
`